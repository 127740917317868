import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Phone from '@mui/icons-material/Phone';


interface UserProfile {
  name: string
  surname: string
  tel: string
  email: string
  title: string
  org: string[]
  website: string
  photo_url: string
  vcard: string
}

function Profile() {
  const { profileId } = useParams();
  const theme = useTheme()

  const [profile, setProfile] = useState<UserProfile | undefined>(undefined)

  useEffect(() => {
    fetch(`https://api.likewise.cards/${profileId}`)
      .catch(_ => ({ json: () => undefined }))
      .then(response => response.json())
      .then(data => setProfile(data))
  }, [])

  return (
    <Card sx={{ maxWidth: 345, padding: theme.spacing, paddingTop: `calc(${theme.spacing} + 60px)`, margin: 'auto' }} elevation={20} >
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={profile?.photo_url}
          alt={`${profile?.name} ${profile?.surname}`}
        />
        <CardContent>
          <Typography variant="h4" fontWeight="bold" component="div">
            {`${profile?.name} ${profile?.surname}`}
          </Typography>
          <Typography variant="h6" gutterBottom color="text.secondary">
            {`${profile?.title}`}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            {`${profile?.org[0]}`} - <b>{`${profile?.org[1]}`}</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            bla bla bla
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton aria-label="Call">
            <Phone />
          </IconButton>
          <Button style={{ marginLeft: 'auto' }} size="small" onClick={() => {
            if (profile === undefined) {
              return
            }
            function base64ToArrayBuffer(base64: string) {
              var binaryString = atob(base64);
              var bytes = new Uint8Array(binaryString.length);
              for (var i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              return bytes.buffer;
            }

            const your_bytes = base64ToArrayBuffer(profile.vcard || '')
            const blob = new Blob([your_bytes], { type: "text/vcard" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); // create blob: url
            link.download = `${profile.name} ${profile.surname}.vcard`;
            link.click();
          }
          }>Save Contacts</Button>
        </CardActions>
      </CardActionArea>
    </Card >
  );
}

export default Profile;
