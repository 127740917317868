import { Outlet } from "react-router-dom";
import ResponsiveAppBar from './appbar';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function App() {
  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ width: '100%' }}>
        <Grid marginTop='10pt' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={12} >
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default App;
